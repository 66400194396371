<template lang="pug">
div(class="mt-8 mb-0")
  v-flex(row no-gutter align-end)
    v-flex( md9 )
    v-flex( md3 row)

      v-menu(
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="initDate"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      )
        template(v-slot:activator="{ on, attrs }")
          v-text-field(
            v-model="initDate"
            label="Ano/Mês"
            class="pt-2 pl-2 pr-2 ma"
            prepend-icon="mdi-calendar"
            outlined
            dense
            readonly
            v-bind="attrs"
            v-on="on"
          )
        v-date-picker(
          v-model="initDate"
          type="month"
          no-title
          scrollable
          locale="pt-BR"
          @change="$refs.menu.save(initDate)"
        )
      v-btn(
        icon
        outlined
        color="primary"
        @click="getAutenticacoes()")
        v-icon mdi-sync
  v-flex
    DataFilter(class="mt-2"
      :filterProps="filters"
      :items="dataset"
      :paginate="false"
      )
      template(v-slot:default="{ items }" )
        v-data-table(
          class="ma-6 mb-0 autenticacaoTable"
          :headers="headersHash"
          :items="items"
          :single-expand="false"
          :expanded.sync="expanded"
          :loading="items.length == 0"
          loading-text="Carregando... Aguarde um momento"
          item-key="id"
          :items-per-page="30"
          :footer-props="{ itemsPerPageOptions: [30, 50, 100, 200, 500, 1000], itemsPerPageText: 'Itens por página'}"
          show-expand
        )

          template(v-slot:item.status="{ headers, item }")
            v-tooltip(bottom)
              template(v-slot:activator="{ on }")
                v-icon(
                  v-on="on"
                  alt="Status Autenticação"
                  :color="(getStatusColor(item.status)).color"
                ) mdi-circle
              span {{ (getStatusColor(item.status)).text }}

          template(v-slot:item.geradoem="{ headers, item }")
            | {{ new Date(item.geradoem).toLocaleString() }}

          template(v-slot:expanded-item="{ headers, item }")
            td(:colspan="headers.length")
              v-data-table(
                dense
                :headers="headersEmpresas"
                :items="item.empresas"
                hide-default-footer
                :items-per-page="item.empresas.length"
                class="ma-2 clientesLogsTable"
                width="100%"
              )
                template(v-slot:item.origem="{ item }")
                  v-tooltip(v-if="item.origem.substring(0,1) === '2'" bottom)
                    template(v-slot:activator="{ on }")
                      span(
                        v-on="on"
                      ) {{ item.origem }} 
                    span {{ item.motivo }}
                  span(v-else) {{ item.origem }}

                template(v-slot:item.dataretorno="{ item }")
                  span {{ item.dataretorno > 0 ? new Date(item.dataretorno).toLocaleString() : 'Sem retorno' }}

                template(v-slot:item.registros="{ item }")
                  span {{ item.historico.length == 1 ? '1 Registro' : item.historico.length + ' Registros' }}

                template(v-slot:item.status="{ item }")
                  span {{ (getStatusColor(item.status)).text }}

                template(v-slot:item.historico="{ item }")
                  v-tooltip(bottom)
                    template(v-slot:activator="{ on }")
                      v-btn(
                        v-on="on"
                        fab
                        outlined
                        x-small
                        color="primary"
                        @click="openDialogHistorico(item)"
                      )
                        v-icon(small) fa-solid fa-inbox
                    span Histórico

                template(v-slot:item.actions="{ item }")

                  v-tooltip(bottom)
                    template(v-slot:activator="{ on }")
                      v-btn(
                        class="ml-2"
                        v-on="on"
                        fab
                        outlined
                        x-small
                        color="primary"
                        @click="openDialogChave(item)"
                      )
                        v-icon(small) fa-regular fa-copy
                    span Copiar chave

                  v-tooltip(v-if="item.copia.length > 0" bottom)
                    template(v-slot:activator="{ on }")
                      v-btn(
                        class="ml-2"
                        v-on="on"
                        fab
                        outlined
                        x-small
                        color="primary"
                        @click="openDialogHistoryCopiaChave(item.copia)"
                      )
                        v-icon(small) fa-solid fa-clock-rotate-left
                    span Historico de cópia de chave

  v-dialog(v-model="dialogChave" max-width="500px")
    v-card
      v-card-title
        span Copiar Chave
        v-spacer
        v-btn(
          icon
          @click="clearCopiarChave()"
        )
          v-icon mdi-close
      v-card-text(class="pt-2")
        p Para copiar a chave, informe o motivo de enviar a chave para o cliente, e clique no botão Copiar.
        v-text-field(
          v-model="motivoChave"
          label="Motivo"
          outlined
          minlength="10"
          maxlength="100"
          counter="100"
          :rules="[v => (v.length >= 10 || v.length == 0) || 'Motivo informado muito curto']"
        )
      v-card-actions(class="pa-0 pb-2")
        v-spacer
        v-btn(
          color="primary"
          text
          :disabled="motivoChave.length < 10"
          @click="copyToClipboard()"
        ) Copiar

  v-dialog(v-model="dialogHistoryCopiaChave" max-width="500px")
    v-card
      v-card-title
        span Historico de cópia de chave
        v-spacer
        v-btn(
          icon
          @click="dialogHistoryCopiaChave = false"
        )
          v-icon mdi-close
      v-card-text(class="pt-2 mb-0")
        v-divider
        v-timeline(align-top dense)
          v-timeline-item(v-for="(row, i) in itemHistoricoChave" :key="i"  right small color="success")
            div {{ new Date(row.datahora).toLocaleString() }} - {{ row.usuario.split(':')[1] }}
            div Motivo: {{ row.motivo }}
        v-divider
  
  v-dialog(v-model="dialogHistorico" max-width="1300px")
    v-card
      v-card-title(class="pb-0")
        span Histórico de Autenticação          
        v-spacer 
        v-btn(
          icon
          @click="dialogHistorico = false"
        )
          v-icon mdi-close
      v-card-text(class="pt-0 mb-0")
        h5 {{ itemHistorico[0].codigocliente }} - {{ itemHistorico[0].nome }} - {{ itemHistorico[0].cpfcnpj }}
        v-data-table(
          class="ma-2 mt-4"
          :headers="headersHistorico"
          :items="itemHistorico"
          hide-default-footer
          dense
          :items-per-page="itemHistorico.length"
          width="100%"
        )
          template(v-slot:item.dataretorno="{ item }")
            span {{ new Date(item.dataretorno).getTime() > 0 ? new Date(item.dataretorno).toLocaleString() : ''}}

          template(v-slot:item.datageracao="{ item }")
            span {{ new Date(item.datageracao).toLocaleString()}}

          template(v-slot:item.status="{ item }")
            span {{ (getStatusColor(item.status)).text }}

          template(v-slot:item.origem="{ item }")
            span {{ item.origem == 1 ? '1 - Autenticação mensal' : '2 - Manual' }}      
        v-divider

</template>

<script>
import { mapMutations } from 'vuex';
import csapi from '@/api/csapi';

export default {
  data: () => ({
    dialogChave: false,
    dialogHistorico: false,
    dialogHistoryCopiaChave: false,
    menu: false,
    initDate: new Date().toISOString().substr(0, 7),
    itemChave: {},
    itemHistoricoChave: [],
    itemHistorico: [
      {
        codigoCliente: '',
        nome: '',
        cpfcnpj: '',
      }
    ],
    filters: {
      includeSearch: true,
      items: [],
    },
    expanded: [],
    headersHash: [
      { text: 'ID', value: 'idweb', width: '50', sortable: false },      
      { text: 'Cliente', value: 'nome' },
      { text: 'Hash', value: 'hash', width: '180' },
      { text: 'ID Servidor', value: 'idservidor', width: '140' },
      { text: 'Gerado em', value: 'geradoem' },
      { text: 'Autenticado em', value: 'autenticadoem' },
      { text: 'Status', value: 'status', align: 'center', width: '100', sortable: true },
      { text: '', value: 'data-table-expand', align: 'center', sortable: false },
    ],
    headersEmpresas: [
      { text: 'Self', value: 'codigocliente', align: 'center', width: '80' },
      { text: 'Nome cliente self', value: 'nome', width: '300' },
      { text: 'CPF/CNPJ', value: 'cpfcnpj',  width: '160' },
      { text: 'Origem Autenticação', value: 'origem', width: '220' },
      { text: 'Motivo Autenticação', value: 'motivo', width: '220' },
      { text: 'Status', value: 'status', align: 'start', width: '100', sortable: true },
      { text: 'Retorno', value: 'dataretorno', align: 'center', width: '110' },
      { text: 'Advertência', value: 'advertencia', sortable: false },
      { text: 'Registros', value: 'registros', sortable: false },
      { text: '', value: 'historico', width: '40', align: 'start', sortable: false },
      { text: '', value: 'actions', width: '120', align: 'start', sortable: false },
    ],
    headersHistorico: [
      { text: 'Gerado em', value: 'datageracao', align: 'start', width: '100', sortable: false },
      { text: 'Autenticação', value: 'hash', align: 'start', width: '300', sortable: false },
      { text: 'Origem Autenticação', value: 'origem', width: '200', sortable: false },
      { text: 'Motivo Autenticação', value: 'motivo', sortable: false },
      { text: 'Status', value: 'status', align: 'start', width: '100', sortable: false },
      { text: 'Retorno', value: 'dataretorno', align: 'start', width: '100', sortable: false },
      { text: 'Advertência', value: 'advertencia', sortable: false },
    ],
    motivoChave: '',
    dataset: [],
  }),
  mounted() {
    this.isControl(true);
    this.setTitle('Autenticação Mensal');
    this.getAutenticacoes();
  },

  watch: {
    dialogChave() {
      if (!this.dialogChave) this.clearCopiarChave();
    },
  },

  methods: {
    ...mapMutations('app', ['setTitle', 'isControl']),
    ...mapMutations('loader', ['showLoader', 'hideLoader']),

    getStringMes(mes) {
      const meses = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro',
      ];
      return meses[mes - 1];
    },

    async getData(url, errorMessage) {
      this.showLoader('Carregando Autenticações...');
      try {
        const res = await csapi.get(url);
        if (res.status === 200) return res.data;
      } catch (error) {
        console.error(error);
        this.$toast.error(`${errorMessage}: ${error.message}`);
      } finally {
        this.hideLoader();
      }
    },

    async postData(url, data, errorMessage) {
      try {
        const res = await csapi.post(url, data);
        if (res.status === 200) return res.data;
      } catch (error) {
        console.error(error);
        this.$toast.error(`${errorMessage}: ${error.message}`);
      }
    },

    setFilters() {
      this.filters.items.length = 0;
      try {
        this.filters.items.push({
          text: 'Cliente',
          type: 'select',
          values: this.dataset.map((item) => ({ field: 'id', text: item.nome, value: item.id })).sort((a, b) => {
            if (a.text == null || b.text == null) return 0;
            return a.text.localeCompare(b.text);
          }),
        });
      } catch (error) {
        console.error('Não foi possivel gerar o filtro de clientes', error);
      }

      try {
        this.filters.items.push({
          text: 'Cliente Self',
          type: 'select',
          values: this.dataset.map((item) => item.empresas.map(
            (empresa) => ({
              text: empresa.nome,
              value: empresa.nome,
              fn: ([row]) => {
                const filter = row.empresas.filter((t) => t.nome === empresa.nome);
                return filter.length > 0 ? row : null;
              },
            }),
          )).flat().sort((a, b) => a.text.localeCompare(b.text)),
        });
      } catch (error) {
        console.error('Não foi possivel gerar o filtro Cliente Self.', error);
      }

      try {
        this.filters.items.push({
          text: 'Status Autenticação',
          type: 'select',
          values: this.dataset.map((item) => ({ 
            text: (this.getStatusColor(item.status)).text, 
            value: (this.getStatusColor(item.status)).text,
            fn: ([row]) => {
              return row.status == item.status ? row : null;
            }, 
          })),
        });
      } catch (error) {
        console.error('Não foi possivel gerar o filtro Status Autenticação.', error);
      }

      try {
        this.filters.items.push({
          text: 'Chave Copiada',
          type: 'select',
          values: this.dataset.map((item) => {
            const flatCopia = item.empresas.map((empresa) => empresa.copia).flat();
            if (flatCopia.length == 0) {
              return { text: 'Todos', value: 'Todos', fn: ([row]) => row };
            }
            return {
              text: 'Com motivo',
              value: 'Com motivo',
              fn: ([row]) => {
                const filter = row.empresas.filter((t) => t.copia.length > 0);
                return filter.length > 0 ? row : null;
              },
            };
          }).flat().sort((a, b) => a.text.localeCompare(b.text)),
        });
      } catch (error) {
        console.error('Não foi possivel gerar o filtro Chave Copiada.', error);
      }
    },

    async getAutenticacoes() {
      const url = `/v1/autenticacao?ano=${this.initDate.substring(0, 4)}&mes=${this.initDate.substring(5, 7)}`;
      const errorMessage = 'Erro ao buscar autenticações';
      const data = await this.getData(url, errorMessage);
      this.dataset = this.normalizaDadosAutenticacao(data);

      if (this.dataset.length > 0) this.setFilters();
    },

    async setMotivoCopiarChave(id) {
      const data = {
        motivo: this.motivoChave,
        usuario: `${this.userInfo().login}:${this.userInfo().name}`,
      };
      const url = `/v1/autenticacao/${id}/copia-chave`;
      const errorMessage = 'Erro ao definir motivo de copiar chave';
      const res = await this.postData(url, data, errorMessage);

      for (let i = 0; i < this.dataset.length; i++) {
        const item = this.dataset[i];
        const index = item.empresas.findIndex((t) => t.id === res.idautenticacao);
        if (index >= 0) {
          this.dataset[i].empresas[index].copia.push(res);
          break;
        }
      }
    },

    getStatusColor(statusArray) {
      if (statusArray === null) return { color: 'grey', text: 'Sem Ação' };
      if (statusArray.includes('INVALIDO')) return { color: 'error', text: 'Inválido' };
      if (statusArray.includes('ERRO')) return { color: 'error', text: 'Erro' };
      if (statusArray.includes('PENDENTE')) return { color: 'warning', text: 'Pendente' };
      if (statusArray.includes('CHAVE_SUBSTITUIDA')) return { color: 'black', text: 'Chave Substituída' };
      if (statusArray.includes('ENVIADO')) return { color: 'info', text: 'Enviado' };
      if (statusArray.includes('OK')) return { color: 'success', text: 'OK' };
      return { color: 'grey', text: 'Sem Ação' };
    },

    getStatusGeral(statusArray) {
      if (statusArray === null) return null;
      if (statusArray.includes('INVALIDO')) return 'INVALIDO';
      if (statusArray.includes('ERRO')) return 'ERRO';
      if (statusArray.includes('PENDENTE')) return 'PENDENTE';
      // if (statusArray.includes('CHAVE_SUBSTITUIDA')) return 'CHAVE_SUBSTITUIDA';
      if (statusArray.includes('ENVIADO')) return 'ENVIADO';
      if (statusArray.includes('OK')) return 'OK';
      return null;
    },

    normalizaDadosAutenticacao(data) {
      // Normaliza os dados para exibição
      const newArray = JSON.parse(JSON.stringify(data));
      for (let i = 0; i < data.length; i++) {
        data[i].mes = `${this.getStringMes(data[i].mes)}/${data[i].ano}`;
        data[i].datageracao = new Date(data[i].datageracao).getTime();
        data[i].dataretorno = data[i].dataretorno !== null ? new Date(data[i].dataretorno).getTime() : 0;
        data[i].origem = data[i].origem == 1 ? '1 - Autenticação mensal' : '2 - Manual';
        data[i].historico = newArray.filter((t) => t.codigocliente === data[i].codigocliente);
      }

      newArray.length = 0;

      // depois de agrupar no historico, deixa apenas o registro mais recente
      data.sort((a, b) => b.datageracao - a.datageracao);
      for (let i = 0; i < data.length; i++) {
        if (newArray.findIndex((t) => t.codigocliente === data[i].codigocliente) == -1) {
          newArray.push(data[i]);
        }
      }

      data = newArray;

      const arrayClientes = data.map((item) => item.cliente);
      const arrayClientesFiltered = new Array();

      for (let i = 0; i < arrayClientes.length; i++) {
        const item = arrayClientes[i];
        if (arrayClientesFiltered.findIndex((t) => t.id === item.id) == -1) {
          arrayClientesFiltered.push(item);
        }
      }

      // Monta parte dos dados utilizados no primeiro nivel de exibiçã
      for (let i = 0; i < arrayClientesFiltered.length; i++) {
        const item = arrayClientesFiltered[i];
        item.empresas = data.filter((t) => t.cliente.id === item.id);
        const hd = item.empresas[0].serialhd;
        item.idservidor = `${hd.substring(0, 4)}-${hd.substring(4, 8)}-${hd.substring(8, 12)}`;
        item.geradoem = data.filter((t) => t.cliente.id === item.id).map((t) => t.datageracao).reduce((a, b) => Math.min(a, b));
        item.autenticadoem = data.filter((t) => t.cliente.id === item.id).map((t) => t.dataretorno).reduce((a, b) => Math.max(a, b));        
        let status = data.filter((t) => t.cliente.id === item.id).map((t) => t.status);        
        item.status = this.getStatusGeral(status);
        item.autenticadoem = item.status == 'OK'? new Date(item.autenticadoem).toLocaleString() : 'Não autenticado';
      }

      for (let i = 0; i < arrayClientesFiltered.length; i++) {
        const empresas = arrayClientesFiltered[i].empresas;
        for (let j = 0; j < empresas.length; j++) {
          delete empresas[j].cliente;
          delete empresas[j].serialhd;
        }
      }

      arrayClientesFiltered.sort((a, b) => a.idweb - b.idweb);

      return arrayClientesFiltered;
    },

    userInfo() {
      const user = JSON.parse(JSON.stringify(this.$store.state.auth.user));
      return user;
    },

    openDialogChave(item) {
      this.itemChave = item;
      this.dialogChave = true;
    },

    clearCopiarChave() {
      this.motivoChave = '';
      this.itemChave = {};
      this.dialogChave = false;
    },

    openDialogHistoryCopiaChave(item) {
      this.itemHistoricoChave = item;
      this.dialogHistoryCopiaChave = true;
    },

    clearHistoryCopiaChave() {
      this.itemHistoricoChave = {};
      this.dialogHistoryCopiaChave = false;
    },

    openDialogHistorico(item) {
      let historico = JSON.parse(JSON.stringify(item.historico));
      this.itemHistorico = historico.sort((a, b) => new Date(b.datageracao).getTime() - new Date(a.datageracao).getTime());
      this.dialogHistorico = true;
    },

    clearHistorico() {
      this.itemHistorico = [];
      this.dialogHistorico = false;
    },

    async copyToClipboard() {
      await this.setMotivoCopiarChave(this.itemChave.id);

      const chave = this.itemChave.hash;

      const input = document.createElement('input');
      document.body.appendChild(input);
      input.value = '';
      input.focus();
      input.select();

      async function copyPageUrl() {
        try {
          await navigator.clipboard.writeText(chave);
          document.body.removeChild(input);
        } catch (err) {
          console.error('Falha ao Copiar: ', err);
          document.body.removeChild(input);
        }
      }

      copyPageUrl();

      this.$toast.success('Chave copiada com sucesso');

      this.clearCopiarChave();
    },
  },

};
</script>

<style>
.autenticacaoTable .v-data-table__wrapper {
  height: calc(100vh - 270px);
  overflow-y: auto;
}

.clientesLogsTable .v-data-table__wrapper {
  height: auto;
  overflow-y: auto;
}
</style>